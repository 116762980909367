<template>
    <div id="testimonials" class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="row-title" title="Testemunhos">Testemunhos</div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div id="testimonials-slider">
                        <div v-for="(testimonial, index) in testimonials" :key="index">
                            <div class="details-holder">
                                <!--<img class="photo" src="../assets/images/person1.jpg" alt="">-->
                                <h4>{{ testimonial.name}}</h4>
                                <h5>{{ testimonial.position}}</h5>
                                <p>{{ testimonial.message}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Testimonials from '../database/testimonials.json'

  export default {
    name: "site-testimonials",
    data() {
      return {
        testimonials: Testimonials
      }
    },
    mounted: function () {
      window.$("#testimonials-slider", "#testimonials").slick({
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
      });
    }
  }
</script>

<style scoped>
    #testimonials {
        background-color: #eee;
    }

    #testimonials .row-title {
        color: #6c84af;
    }
</style>