<template>
    <nav id="mainNav" class="navbar navbar-default navbar-full">
        <div class="container container-nav">
            <div class="navbar-header">
                <button aria-expanded="false" type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#bs">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand page-scroll" href="index.html">
                    <img class="logo" src="../assets/images/logo.png" alt="eKutiva Solutions">
                </a>
            </div>
            <site-menu></site-menu>

        </div>
    </nav>
</template>

<script>
    import SiteMenu from './SiteMenu'

    export default {
        name: "site-header",
        components: {
            SiteMenu
        }
    }
</script>

<style scoped>
    .logo {
        width: 75px;
        margin-top: -18px;
    }

    @media (max-width: 768px){
        .logo {
            width: 45px;
        }
    }
</style>