<template>
    <div id="footer" class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-xs-6 col-sm-3 col-md-3">
                    <div class="footer-menu-holder">
                        <h4>Empresa</h4>
                        <ul class="footer-menu">
                            <li><a href="about.html">Sobre nós</a></li>
                            <li><a href="blog.html">Blog</a></li>
                            <li><a href="faq.html">FAQ</a></li>
                            <!--<li><a href="contact.html">Contact us</a></li>-->
                        </ul>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-3 col-md-3">
                    <div class="footer-menu-holder">
                        <h4>Serviços</h4>
                        <ul class="footer-menu">
                            <li><a href="webhosting.html">Desenv. de sistemas</a></li>
                            <li><a href="webhosting.html">Hospedagem</a></li>
                            <!--<li><a href="webhosting.html">Dedicated Hosting</a></li>-->
                            <!--<li><a href="domain.html">Domain Names</a></li>-->
                        </ul>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-3 col-md-3">
                    <div class="footer-menu-holder">
                        <h4>Produtos</h4>
                        <ul class="footer-menu">
                            <li v-for="(product, index) in products" :key="index">
                                <a :href="product.url">{{ product.name}}</a>
                            </li>
                            <!--<li><a href="portal.html">Quick-e-Pay</a></li>-->
                            <!--<li><a href="portal.html">eKuMailer</a></li>-->
                        </ul>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-3 col-md-3">
                    <div class="address-holder">
                        <div class="phone"><i class="fa fa-phone"></i> {{ company.tel }}</div>
                        <div class="email"><i class="fa fa-envelope"></i> {{ company.email }}</div>
                        <div class="address">
                            <i class="fa fa-map-marker"></i>
                            <div>{{ company.address.city }}, {{ company.address.country }}<br>
                                {{ company.address.avenue }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="social row">
                <div v-for="(social, index) in socials" :key="index" class="col-xs-2">
                    <a :href="social.url">
                        <i :class="[social.icon]"></i>
                    </a>
                </div>
                <!--<div class="col-xs-2"><a href="#"><i class="fa fa-twitter"></i></a></div>-->
                <!--<div class="col-xs-2"><a href="#"><i class="fa fa-youtube-play"></i></a></div>-->
                <!--<div class="col-xs-2"><a href="#"><i class="fa fa-behance"></i></a></div>-->
                <!--<div class="col-xs-2"><a href="#"><i class="fa fa-dribbble"></i></a></div>-->
                <!--<div class="col-xs-2"><a href="#"><i class="fa fa-pinterest-p"></i></a></div>-->
            </div>
        </div>
    </div>
</template>

<script>
  import Socials from '../database/social.json'
  import Products from '../database/products.json'
  import Company from '../database/company.json'

  export default {
    name: "site-footer",
    data() {
      return {
        socials: Socials,
        products: Products,
        company: Company
      }
    }
  }
</script>

<style scoped>
    #footer {
        background-color: #333;
    }

    #footer .social a .fa {
        color: #0397ff;
    }

    #footer ul.footer-menu li a:hover,
    #footer ul.footer-menu li a:focus,
    #footer ul.footer-menu li.active a {
        color: #0397ff;
        text-decoration: none;
    }

    #footer .social a:hover .fa,
    #footer .social a:focus .fa {
        color: #ffffff;
    }
</style>