import { render, staticRenderFns } from "./SiteMenu.vue?vue&type=template&id=635d6964&scoped=true&"
import script from "./SiteMenu.vue?vue&type=script&lang=js&"
export * from "./SiteMenu.vue?vue&type=script&lang=js&"
import style0 from "./SiteMenu.vue?vue&type=style&index=0&id=635d6964&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "635d6964",
  null
  
)

component.options.__file = "SiteMenu.vue"
export default component.exports