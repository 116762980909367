<template>
    <div class="home">
        <site-top-slider></site-top-slider>
        <site-separator-blue url="#" url_text="Open-Source">
            Veja as nossas aplicações e pacotes Open-Source<br>
        </site-separator-blue>
        <site-apps></site-apps>
        <site-testimonials></site-testimonials>
        <site-features></site-features>
        <site-separator-blue url="#" url_text="Regista-te">
            Quer contribuir para a @eKutivaSolutions?<br>
            Regista-te hoje!
        </site-separator-blue>
    </div>
</template>

<script>
  import SiteApps from '@/components/SiteApps'
  import SiteFeatures from '@/components/SiteFeatures'
  import SiteSeparator from '@/components/SiteSeparator'
  import SiteTestimonials from '@/components/SiteTestimonials'
  import SiteTopSlider from '@/components/SiteTopSlider'
  import SiteSeparatorBlue from "@/components/SiteSeparatorBlue"

  export default {
    name: 'home',
    components: {
      SiteSeparatorBlue,
      SiteApps,
      SiteFeatures,
      SiteSeparator,
      SiteTestimonials,
      SiteTopSlider
    }
  }
</script>
