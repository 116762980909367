<template>
    <div>
        <site-header></site-header>
        <router-view></router-view>
        <site-footer></site-footer>
    </div>
</template>

<script>
  import SiteHeader from '@/components/SiteHeader'
  import SiteFooter from '@/components/SiteFooter'

  export default {
    name: 'app',
    components: {
      SiteHeader,
      SiteFooter
    }
  }
</script>

<style>
    /*@import './assets/css/bootstrap.min.css'*/
    /*@import './assets/css/font-awesome.min.css'*/
    /*@import './assets/css/slick.css'*/
    /*@import './assets/css/style.css'*/
</style>
