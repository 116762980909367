<template>
    <div id="more-features" class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row-title" title="Outras vantagens">Porque optar pelos nossos serviços?</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-4">
                    <div class="mfeature-box">
                        <div class="mfeature-icon">
                            <div class="icon-bg"><img src="../assets/images/cloud-light.png" alt=""/></div>
                            <div class="icon-img"><img src="../assets/images/feature1.png" alt=""/></div>
                        </div>
                        <div class="mfeature-title">Disponibilidade %99.9</div>
                        <div class="mfeature-details">Garantimos grande disponibilidade dos nossos serviços na nuvem
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <div class="mfeature-box">
                        <div class="mfeature-icon">
                            <div class="icon-bg"><img src="../assets/images/cloud-light.png" alt=""/></div>
                            <div class="icon-img"><img src="../assets/images/feature2.png" alt=""/></div>
                        </div>
                        <div class="mfeature-title">Domain Names</div>
                        <div class="mfeature-details">Mauris at libero sed justo pretium maximus ac non ex. Donec sit
                            amet ultrices dolo.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <div class="mfeature-box">
                        <div class="mfeature-icon">
                            <div class="icon-bg"><img src="../assets/images/cloud-light.png" alt=""/></div>
                            <div class="icon-img"><img src="../assets/images/feature3.png" alt=""/></div>
                        </div>
                        <div class="mfeature-title">Soluções Inteligentes</div>
                        <div class="mfeature-details">Soluções que permitem-lhe ter mais tempo
                            livre sem preocupação adadsadasdd a ad asd
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <div class="mfeature-box">
                        <div class="mfeature-icon">
                            <div class="icon-bg"><img src="../assets/images/cloud-light.png" alt=""/></div>
                            <div class="icon-img"><img src="../assets/images/feature4.png" alt=""/></div>
                        </div>
                        <div class="mfeature-title">Servidores Seguros</div>
                        <div class="mfeature-details">Mauris at libero sed justo pretium maximus ac non ex. Donec sit
                            amet ultrices dolo.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <div class="mfeature-box">
                        <div class="mfeature-icon">
                            <div class="icon-bg"><img src="../assets/images/cloud-light.png" alt=""/></div>
                            <div class="icon-img"><img src="../assets/images/feature5.png" alt=""/></div>
                        </div>
                        <div class="mfeature-title">Customização</div>
                        <div class="mfeature-details">Mauris at libero sed justo pretium maximus ac non ex. Donec sit
                            amet ultrices dolo.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <div class="mfeature-box">
                        <div class="mfeature-icon">
                            <div class="icon-bg"><img src="../assets/images/cloud-light.png" alt=""/></div>
                            <div class="icon-img"><img src="../assets/images/feature6.png" alt=""/></div>
                        </div>
                        <div class="mfeature-title">Integração de Pagamentos</div>
                        <div class="mfeature-details">Mauris at libero sed justo pretium maximus ac non ex. Donec sit
                            amet ultrices dolo.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "site-features"
    }
</script>

<style scoped>

</style>