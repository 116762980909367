<template>
    <div id="info" class="container-fluid">
        <canvas id="infobg" data-paper-resize="true"></canvas>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row-title">Regret-free choise<br>
                        The best of the best in all times!
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="info-text">adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure.</div>

                    <a href="#" class="white-green-shadow-button">All Features</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "site-separator"
    }
</script>

<style scoped>

</style>