<template>
    <div id="apps" class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row-title" title="Nossas plataformas">As melhores plataformas nacionais, a um clique de
                        distância
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="apps-holder">
                        <div class="apps-links-holder">
                            <div v-for="(app, index) in apps" :key="index"
                                 :class="['app-icon-holder', 'app-icon-holder'+index, index == '0' ? 'opened' : '']"
                                 :data-id="index"
                            >
                                <div class="app-icon">
                                    <img :src="app.image" :alt="app.name"/>
                                </div>
                                <div class="app-title">{{app.name}}</div>
                            </div>
                        </div>
                        <div class="apps-details-holder">
                            <div class="app-details">
                                <div v-for="(app, index) in apps" :key="index"
                                     :class="['app-details'+index, index == '0' ? 'show-details' : '']">
                                    <div class="app-text">
                                        {{ app.long_text}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Products from '../database/products.json'

  export default {
    name: "site-apps",
    data() {
      return {
        products: Products
      }
    },
    computed: {
      apps() {
        return this.products.filter(product => product.app === true)
      }
    },
    mounted: function () {

      window.$(window).on("load", function () {
        // Adding hover style for the feature box
        var featureBox = window.$(".mfeature-box", "#features");
        featureBox.on("mouseover", function () {
          featureBox.removeClass("active");
          window.$(this).addClass("active");
          return false;
        });
        // Adding hover style for the info box
        var infobox = window.$(".info-box", "#contact-info");
        var infoboxBorderColor = window.$('div[class^="col-"]:nth-child(2) .info-box', '#contact-info').css("border-color");
        infobox.on("mouseover", function () {
          infobox.css("border-color", "transparent");
          return false;
        });
        infobox.on("mouseout", function () {
          infobox.css("border-color", infoboxBorderColor);
          return false;
        });
        // Apps Section hover function
        var appHolder = window.$(".app-icon-holder", "#apps");

        appHolder.on("mouseover", function () {
          appHolder.removeClass("opened");
          window.$(this).addClass("opened");
          window.$(".show-details", "#apps").removeClass("show-details");
          window.$(".app-details" + window.$(this).data("id"), "#apps").addClass("show-details");
        });
      });
    }
  }
</script>

<style scoped>

</style>