<template>
    <div id="bluebg-info" class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="text">
                        <slot></slot>
                    </div>
                    <a v-if="url" :href="url" class="white-button">{{ url_text}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "site-separator-blue",
        props: ['url', 'url_text']
    }
</script>

<style scoped>

</style>