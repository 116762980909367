<template>
    <div style="height: 1px;" role="main" aria-expanded="false" class="navbar-collapse collapse" id="bs">
        <ul class="nav navbar-nav navbar-right">
            <li><a href="/">Início</a></li>
            <li class="dropdown" v-if="products.length">
                <a href="#">Produtos <span class="caret"></span></a>
                <ul class="dropdown-menu">
                    <li v-for="(product, index) in products" :key="index">
                        <a :href="product.url" :title="product.desc">{{ product.name}}</a>
                    </li>
                    <!--<li><a href="#">Quick-e-Pay</a></li>-->
                    <!--<li><a href="#">eKuMailer</a></li>-->
                    <!--<li><a href="blog.html">Blog</a></li>-->
                </ul>
            </li>
            <li><a href="#">Blog</a></li>
            <li><a href="#">Sobre nós</a></li>
            <li><a href="#">FAQ</a></li>
            <!--<li><a href="contact.html">Contact us</a></li>-->
            <!--<li><a class="signin-button" href="signin.html">Sign in</a></li>-->
            <li><a class="chat-button" href="#">Fale conosco</a></li>
        </ul>
    </div>
</template>

<script>
  import Products from '../database/products.json'

  export default {
    name: "site-menu",
    data() {
      return {
        products: Products
      }
    }
  }
</script>

<style scoped>

</style>