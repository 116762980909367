<template>
    <div id="top-content" class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div id="main-slider">
                        <div class="slide domainsearch-slide" title="Bem vindo!">
                            <div class="image-holder"><img src="../assets/images/bg1.png" alt=""/></div>
                            <div class="b-title">
                                Somos um parceiro tecnológico de servicos digitais.<br>
                                Sem frescuras, entregamos produtos de acordo com a sua necessidade.
                            </div>
                        </div>
                        <div class="slide info-slide1" title="Aplicações">
                            <div class="image-holder"><img src="../assets/images/main-slide-img1.png" alt=""/></div>
                            <div class="text-holder">Criamos aplicações sólidas e seguras<br>
                                Encomende a sua ainda hoje.
                            </div>
                            <div class="button-holder"><a href="signup.html" class="blue-button">Contacte-nos</a></div>
                        </div>
                        <div class="slide info-slide2" title="Webdesign">
                            <div class="image-holder"><img src="../assets/images/main-slide-img2.png" alt=""/></div>
                            <div class="text-holder">Leve o seu trabalho para um outro nível<br>
                                Deixe o resto conosco.
                            </div>
                            <div class="button-holder">
                                <a href="signup.html" class="blue-button">Solicite uma cotação</a>
                            </div>
                        </div>

                        <div class="slide info-slide1" title="Hospedagem">
                            <div class="image-holder"><img src="../assets/images/img-b1.png" alt=""/></div>
                            <div class="text-holder">Hospedagem segura e grande disponibilidade dos seus serviços<br>
                                Focalize-se somente na produção.
                            </div>
                            <div class="button-holder"><a href="signup.html" class="blue-button">Fale conosco</a></div>
                        </div>

                        <div class="slide domainsearch-slide" title="Notícias">
                            <div class="image-holder"><img src="../assets/images/bg1.png" alt=""/></div>
                            <div class="b-title">Registre-se para estar sempre actualizado sobre as nossas novidades
                            </div>
                            <div class="domain-search-holder">
                                <form id="domain-search">
                                    <input id="domain-text" type="text" name="email"
                                           placeholder="Introduza o seu email aqui"/>
                                    <input id="search-btn" type="submit" name="submit" value="Registar"/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "site-top-slider",
        mounted: function () {
            window.$("#main-slider", "#top-content").slick({
                dots: true,
                customPaging: function (slider, i) {
                    return '<button class="tab">' + window.$(slider.$slides[i]).attr('title') + '</button>';
                },
                arrows: false,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true
            });
        }
    }

</script>

<style scoped>
    #main-slider .info-slide2 {
        padding-top: 0;
        padding-bottom: 130px;
    }

    #main-slider .info-slide1 {
        padding-top: 0px;
        padding-bottom: 140px;
    }
</style>